<script lang="ts">
    import { getTheme } from '@/utility/theming/darkmode.ts';

    export let title = '';
    export let value: number = 0;
    export let info = '';

    function getDarkModeGradient(value: number): string {
        if (value < 20) {
            return 'from-[#342525] to-[#222222]';
        } else if (value >= 20 && value <= 60) {
            return 'from-[#343425] to-[#222222]';
        } else {
            return 'from-[#25342B] to-[#222222]';
        }
    }

    function getTextColor(value: number): string {
        if (value < 20) {
            return 'text-[#FF0004]';
        } else if (value >= 20 && value <= 60) {
            return 'text-[#FFAA00]';
        } else {
            return 'text-[#00FF95]';
        }
    }

    function getLightModeGradient(value: number): string {
        if (value < 20) {
            return 'from-[#E5ABAB] to-[#EAEAEA]';
        } else if (value >= 20 && value <= 60) {
            return 'from-[#FFFFAA] to-[#FFFFDD]';
        } else {
            return 'from-[#AAFFAA] to-[#DDEEDD]';
        }
    }
</script>

<div
    class="{`flex w-[100%] max-w-[407px] items-start justify-between rounded-lg bg-gradient-to-r p-4 md:w-[36%] ${
        getTheme() === 'dark' ? getDarkModeGradient(value) : getLightModeGradient(value)
    }`}"
>
    <div>
        <p class="text-[15px] font-semibold text-[#000000] dark:text-[#FFFFFF]">{title}</p>
        <p class="{`text-2xl font-bold ${getTextColor(value)}`}">{value}%</p>
    </div>
    <button aria-label="{info}" class="text-gray-400 hover:text-white">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        >
        <circle cx="12" cy="12" r="10"></circle>
        <path d="M12 8a3 3 0 0 1 3 3c0 1.5-1.5 2-2 2s-1 0.5-1 1"></path>
        <line x1="12" y1="17" x2="12" y2="17.01"></line>
        </svg>
    </button>
</div>
